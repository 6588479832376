body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.text-center{
  text-align: center;
}
.beeGifDiv1{
  position: relative;
  animation: linear infinite;
  animation-name: run;
  animation-duration: 10s;
}

.benefitrow img{
  width: 100%;
}
.beeImage{ 
  width: 100px;
}
@keyframes run {
  0% {
    left: 0;
  }
  50% {
    left: calc(100% - 100px);
  }
  
}

h4 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.one h4 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}
.card{
  height:680px;
}
.card-title{
  font-size: 1.6rem;
  font-weight: lighter;
}
.card-body{
  border-top:1px solid #999;
  border-radius: 1%;
}
.two h4 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 0px;
}
.contact p{
  margin-top: 10px;
  text-transform: none;
  font-size:1.2em;
  font-weight: normal;
  font-style: italic; font-family: "Playfair Display","Bookman",serif;
  color:aliceblue; letter-spacing:-0.005em; word-spacing:1px;
  letter-spacing:none;
}
.carouselCss{
  margin-top: 100px;
}

p{
  margin-top: 10px;
  text-transform: none;
  font-size:1.2em;
  font-weight: normal;
  font-style: italic; font-family: "Playfair Display","Bookman",serif;
  color:#999; letter-spacing:-0.005em; word-spacing:1px;
  letter-spacing:none;
}
.two p{
  margin-top: 10px;
  text-transform: none;
  font-size:1.2em;
  font-weight: normal;
  font-style: italic; font-family: "Playfair Display","Bookman",serif;
  color:#999; letter-spacing:-0.005em; word-spacing:1px;
  letter-spacing:none;
}
.one h4:before {
  width: 130px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 45%;
  margin-left: -14px;
  background-color: #b80000;
}
.one h4:after {
  width: 200px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 25px;
  left: 45%;
  margin-left: -50px;
  background-color: #b80000;
}
.popup{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  
  z-index: 2;
}
.popup-inner{
  position: relative;
  padding:20px;
  margin-top: 70px;
  width: 50%;
  height: 80%;
  
  background-color:transparent;

}
.offerImage{
  width: 100%;
  height: 100%;
}
.closeButton{
  margin-top:0px;
  width: 30px;
  height: 30px;
  cursor: pointer;

}
.bee {
  position: absolute;
  top: 20vh;
  width: 75px;
  height: 80px;
  background: url(//cdn.ficsystems.com/_new/common/3.0/images/characters/earthday-bee.svg);
  animation: walk-cycle 0.1s infinite steps(6), move 10s forwards linear;
}
.beeNotification{
  border: 1px solid black;
  position: absolute;
  top: 40vh;
  width: 100vh;
  height: 80px;
  animation: walk-cycle 0.1s infinite steps(6), move 10s forwards linear;
}
.leftLine{
  border: 1px solid black;
  position: absolute;
  top: 40vh;
  height: 1px;
  width: 100px;
  border: 1px solid black;
  transform: rotate(45deg);
  animation: walk-cycle 0.1s infinite steps(6), move 10s forwards linear;
}
.rightLine{
  margin-top:-8px;
  transform: rotate(45deg);
  width: 100px;
  margin-left: 30px;
  height: 1px;
  border: 1px solid black;
}
@-webkit-keyframes walk-cycle {
  0%   {background-position: 0 0;}
  100% {background-position: -450px 0;}
}

@keyframes move {
  0%    { transform: translate(-10vw, 10vh); }
  10%   { transform: translate(0vw, 15vh); }
  20%   { transform: translate(10vw, 20vh); }
  30%   { transform: translate(20vw, 15vh); }
  40%   { transform: translate(30vw, 10vh); }
  50%   { transform: translate(40vw, 5vh); }
  60%   { transform: translate(50vw, 0vh); }
  70%   { transform: translate(65vw, 5vh); }
  80%   { transform: translate(80vw, 10vh); }
  90%   { transform: translate(95vw, 15vh); }
  100%  { transform: translate(110vw, 20vh); }
}
.img-thumbnail{
 padding-top: 0px;
 height: 35rem;
}
@media (max-width: 576px) {
  .img-thumbnail{
    padding-top: 0px;
    height: 15rem;
   }
   .popup-inner{
    position: relative;
    padding:20px;
    margin-top: 70px;
    width: 100%;
    height: 70%;
    
    background-color:transparent;
  
  }
}
@media (max-width:900px) {
  .img-thumbnail{
    padding-top: 0px;
    height: 15rem;
   }
   .popup-inner{
    position: relative;
    padding:20px;
    margin-top: 70px;
    width: 100%;
    height: 70%;
    
    background-color:transparent;}
}